import React from 'react';
import { useNavigate } from 'react-router-dom';
const NoPage = () => {
  const navigate = useNavigate(); 

  const handleBack = () => {
    navigate(-1); 
  };
  return <div className='flex items-center justify-center h-[100vh]' >
    
  </div>
};

export default NoPage;